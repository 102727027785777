import { render, staticRenderFns } from "./rolesAndPermission.vue?vue&type=template&id=33f3fe69&scoped=true&"
import script from "./rolesAndPermission.vue?vue&type=script&lang=js&"
export * from "./rolesAndPermission.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33f3fe69",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCol,VContainer,VRow,VTreeview})


/* hot reload */
if (module.hot) {
  var api = require("N:\\diglogix\\diglogix-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('33f3fe69')) {
      api.createRecord('33f3fe69', component.options)
    } else {
      api.reload('33f3fe69', component.options)
    }
    module.hot.accept("./rolesAndPermission.vue?vue&type=template&id=33f3fe69&scoped=true&", function () {
      api.rerender('33f3fe69', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/workflow/system/rolesAndPermission.vue"
export default component.exports