<template>
  <v-container fluid class="pa-0" fill-height>
    <v-row v-if="!isManageCompany">
      <v-col
        cols="5"
        sm="6"
        md="9"
        lg="10"
        class="text-h5 font-weight-medium"
        :class="$vuetify.breakpoint.mdAndUp ? 'pl-8' : 'pl-3'"
      >
        {{ $t("companies") }}
      </v-col>
      <v-spacer></v-spacer>
      <v-col>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              dark
              outlined
              class="text-none rounded-lg"
              v-on="on"
              >{{ $t("export")
              }}<v-divider vertical class="mx-2 my-n1 primary"></v-divider
              ><v-icon>mdi-chevron-down</v-icon></v-btn
            >
          </template>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="extension in exportFileExtensions"
                :key="extension"
              >
                <v-list-item-content @click="exportData(extension)"
                  ><a>{{ $t(extension) }}</a></v-list-item-content
                >
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row v-if="!isManageCompany" class="mb-2">
      <v-divider></v-divider>
    </v-row>
    <v-card
      v-if="!isManageCompany"
      elevation="0"
      width="100%"
      :class="$vuetify.breakpoint.smAndDown ? 'mb-12' : null"
    >
      <v-card-title class="pr-0">
        <v-row>
          <span
            class="text-subtitle-2 my-auto"
            :class="$vuetify.breakpoint.mdAndUp ? 'ml-4' : 'ml-1'"
            >{{ $t("totalCompanies") }} : {{ allCompaniesData.length }}</span
          >
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            lg="4"
            md="4"
            xl="4"
            :class="!$vuetify.breakpoint.mdAndUp ? 'pl-0 pr-4' : 'pr-6'"
          >
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              flat
              solo
              background-color="grey lighten-4"
              class="rounded-lg"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="allCompaniesData"
        :items-per-page="!$vuetify.breakpoint.mdAndUp ? -1 : 15"
        :search="search"
        item-key="company_id"
        color="primary"
        class="elevation-0 pa-0"
        :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : null"
        checkbox-color="primary"
        :hide-default-footer="!$vuetify.breakpoint.mdAndUp"
        :loading="allCompaniesData.length == 0"
        loading-text="Loading... Please wait"
        mobile-breakpoint="950"
        disable-sort
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-row class="text-subtitle-2 font-weight-bold ma-0">
            <a
              class="primary--text text-decoration-none"
              @click="manageCompany(item)"
              >{{ item.name }}</a
            ></v-row
          >
        </template>
        <template v-slot:[`item.type`]="{ item }">
          {{ item.type }}
        </template>
        <template v-slot:[`item.state`]="{ item }">
          {{ item.state }}
        </template>
        <template v-slot:[`item.center`]="{ item }">
          {{ item.center }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at }}
        </template>
        <template v-slot:[`item.company_status`]="{ item }">
          <v-chip
            :color="getColor(item.company_status)"
            small
            class="white--text"
          >
            {{ $t(item.company_status) }}
          </v-chip>
        </template>
        <template v-slot:[`item.plan`]="{ item }">
          {{ item.plan }}
        </template>
        <template v-if="!$vuetify.breakpoint.mdAndUp" v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.company_id" class="pb-4">
              <v-card height="200" elevation="0">
                <v-row>
                  <v-col cols="8" class="ml-1">
                    <span class="text-subtitle-2 font-weight-bold">
                      <a
                        class="primary--text text-decoration-none"
                        @click="manageCompany(item)"
                      >
                        {{ item.name }}</a
                      ></span
                    >
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="3" class="pr-0">
                    <v-chip
                      x-small
                      :color="getColor(item.company_status)"
                      class="white--text mr-4"
                    >
                      {{ $t(item.company_status) }}
                    </v-chip>
                  </v-col>
                </v-row>

                <v-row class="ml-1">
                  <v-col cols="4" class="pa-0">{{ $t("type") }}:</v-col>
                  <v-col class="pa-0 font-weight-medium">{{ item.type }}</v-col>
                </v-row>
                <v-row class="ml-1">
                  <v-col cols="4" class="pa-0">{{ $t("stateText") }}:</v-col>
                  <v-col class="pa-0 font-weight-medium">{{
                    item.state
                  }}</v-col>
                </v-row>
                <v-row class="ml-1">
                  <v-col cols="4" class="pa-0">{{ $t("centerText") }}:</v-col>
                  <v-col class="pa-0 font-weight-medium">{{
                    item.center
                  }}</v-col>
                </v-row>
                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0"
                    >{{ $t("createdOnText") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    {{ item.created_at }}</v-col
                  ></v-row
                >
                <v-row class="ml-1">
                  <v-col cols="4" class="pa-0">{{ $t("plan") }}:</v-col>
                  <v-col class="pa-0 font-weight-medium">{{ item.plan }}</v-col>
                </v-row>
              </v-card>
              <v-divider></v-divider>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
    <v-card
      v-else-if="isManageCompany"
      elevation="0"
      width="100%"
      :min-height="$vuetify.breakpoint.height - 160"
    >
      <ManageCompanyLargeScreen
        v-if="isManageCompany"
        :company-tab-labels="tabLabels"
        :company-information="companyInformation"
        @manageCompany="manageCompany"
        @OnChangeStatus="OnChangeStatus"
      />
    </v-card>
    <ConfirmationDialog
      :dialog="confimationDialog"
      :dialog-title="
        confimationDialogObject[companyInformation.status].dialogTitle
      "
      :dialog-body="
        dialogBody
      "
      :dialog-button="
        confimationDialogObject[companyInformation.status].dialogButton
      "
      :company-information="companyInformation"
      @closeDialog="closeConfimationDialog"
      @confirmStatus="upadateCompanyStatus"
    />
  </v-container>
</template>

<script>
import moment from "moment";
import ManageCompanyLargeScreen from "./ManageCompanyLargeScreen.vue";
import ConfirmationDialog from "./ConfirmationDialog.vue";
import { exportDataToFile } from "@/store/utils/utils";
import UserDataVue from "../../../mixins/UserData.vue";
export default {
  name: "Company",
  components: {
    ManageCompanyLargeScreen,
    ConfirmationDialog,
  },
  mixins: [UserDataVue],
  props: {},
  data() {
    return {
      exportFileExtensions: ["xls", "xlsx", "csv"],
      confimationDialogObject: {
        pending: {
          dialogTitle: "confirmActivation",
          dialogBody: "doYouWantToActivate",
          dialogButton: "activate",
        },
        active: {
          dialogTitle: "confirmActivation",
          dialogBody: "doYouWantToActivate",
          dialogButton: "activate",
        },
        inactive: {
          dialogTitle: "confirmDeactivation",
          dialogBody: "doYouWantToDeactivate",
          dialogButton: "deactivate",
        },
      },
      confimationDialog: false,
      companyInformation: { status: "active" },
      tabLabels: [
        "Company and Billing Info",
        "Users",
        "Plan Summary",
        "Roles & Permissions",
      ],
      isManageCompany: false,
      selected: [],
      statusOptions: ["Active", "Inactive"],
      search: "",
      headers: [
        {
          text: "Account Name",
          value: "name",
        },
        { text: "Type", value: "type" },
        { text: "State", value: "state" },
        { text: "Center", value: "center" },
        { text: "Created On", value: "created_at" },
        { text: "Status", value: "company_status" },
        { text: "Plan", value: "plan" },
      ],
      allCompaniesData: [],
      paymentHistoryMap: {},
    };
  },
  computed: {
    centerData() {
      return this.$store.state.User.centerMap;
    },
    dialogBody() {
      if(this.companyInformation.status == 'pending' || this.companyInformation.status == 'active') {
        return `Do you want to activate ${this.companyInformation?.name}?`;
      } else {
        return `Do you want to deactivate ${this.companyInformation?.name}? you can reactive it later, if you wish.`;
      }
    }
  },
  watch: {
    centerData() {
      this.getCompanyData();
    },
  },
  async created() {
    await this.fetchCentersData();
    await this.getCompanyData();
  },
  methods: {
    manageCompany(item) {
      if (item && item.status && item.status === "pending") {
        item.subscription_id = "11111111-1111-1111-1111-111111111111";
      }
      this.companyInformation = item ? item : { status: "active" };
      this.isManageCompany = !this.isManageCompany;
    },
    async getCompanyData() {
      this.allCompaniesData = this.$store.state.Company.allCompanies;
      const paymentHistoryData = await this.$store.dispatch(
        "getPurchaseHistory",
        {
          companyId: this.companyInformation.company_id,
        },
      );
      paymentHistoryData.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      paymentHistoryData.forEach((item) => {
        if (!this.paymentHistoryMap[item.company_id])
          this.paymentHistoryMap[item.company_id] = item.status;
      });
      const subscriptionData = await this.$store.dispatch("getsubscriptions");
      let companyData = await this.$store.dispatch("getCompany");

      if (companyData.status !== "error") {
        companyData.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        companyData.forEach((item) => {
          item.created = item.created_at;
          item.created_at = moment(item.created_at).format("MM/DD/YYYY");
          if (item.centers && item.centers.length > 0) {
            var tempCenterId = item.centers[0].center_id;
          }
          if (this.centerData[tempCenterId]) {
            item.center = this.centerData[tempCenterId]["center_name"];
          }
          if (this.paymentHistoryMap[item.company_id] === "pending")
            item.company_status = "planChangeRequested";
          else item.company_status = item.status;
          if (subscriptionData) {
            let tempPlan = subscriptionData.filter((content) => {
              return content.subscription_id === item.subscription_id;
            });
            if (tempPlan.length > 0) {
              item.plan = tempPlan[0].sub_name;
            } else {
              item.plan = "No Plan found";
            }
          }
        });
        companyData = companyData.filter(
          (element) => element.plan !== "No Plan found",
        );
        if (Object.keys(this.centerData).length > 0) {
          this.$store.commit("setAllCompanies", companyData);
          this.allCompaniesData = companyData;
        }
      }
    },
    getColor(status) {
      if (status && status.toLowerCase() === "pending") return "#C35309";
      else if (status && status.toLowerCase() === "inactive") return "#616161";
      else if (status && status === "planChangeRequested") return "#C35309";
      else return "#1E853A";
    },
    closeConfimationDialog() {
      this.confimationDialog = false;
      this.companyInformation.status =
        this.companyInformation.status == "active" ? "inactive" : "active";
    },
    OnChangeStatus() {
      this.confimationDialog = true;
    },
    async upadateCompanyStatus() {
      const CompanyStatus = await this.$store.dispatch("updateCompany", {
        status: this.companyInformation.status,
        company_id: this.companyInformation.company_id,
      });
      if (CompanyStatus.status === "error") {
        console.log("error");
      } else {
        this.confimationDialog = false;
        this.getCompanyData();
      }
    },
    exportData(extension) {
      const exportData = this.allCompaniesData.map((company) => {
        return {
          "Account Name": company.name,
          Type: company.type,
          State: company.state,
          Center: company.center,
          "Created On":
            company.created_at === "Invalid date" ? "-" : company.created_at,
          Status: this.$t(company.status),
          Plan: company.plan,
        };
      });
      exportDataToFile(exportData, "Companies", extension);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.v-data-table--mobile > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0 !important;
}
.v-select__slot {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0 0px;
}
</style>
