var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "pa-0" },
        [
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "text-h5 font-weight-medium",
                      class: _vm.$vuetify.breakpoint.mdAndUp ? "pl-8" : "pl-5",
                      attrs: { cols: "10", md: "9", lg: "10" },
                    },
                    [_vm._v(" Disable Feature ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-row",
            { staticClass: "py-5", attrs: { justify: "start" } },
            [
              _c("v-treeview", {
                ref: "treeView",
                attrs: {
                  selectable: "",
                  "open-all": "",
                  dense: "",
                  value: _vm.activeNodes,
                  "item-disabled": "locked",
                  items: _vm.items,
                },
                on: { input: _vm.updateNodes },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }