var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "pa-0", attrs: { fluid: "", "fill-height": "" } },
    [
      !_vm.isManageCompany
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "text-h5 font-weight-medium",
                  class: _vm.$vuetify.breakpoint.mdAndUp ? "pl-8" : "pl-3",
                  attrs: { cols: "5", sm: "6", md: "9", lg: "10" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("companies")) + " ")]
              ),
              _c("v-spacer"),
              _c(
                "v-col",
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function (ref) {
                              var on = ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "text-none rounded-lg",
                                      attrs: {
                                        color: "primary",
                                        dark: "",
                                        outlined: "",
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _vm._v(_vm._s(_vm.$t("export"))),
                                    _c("v-divider", {
                                      staticClass: "mx-2 my-n1 primary",
                                      attrs: { vertical: "" },
                                    }),
                                    _c("v-icon", [_vm._v("mdi-chevron-down")]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1904003879
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        [
                          _c(
                            "v-list-item-group",
                            _vm._l(
                              _vm.exportFileExtensions,
                              function (extension) {
                                return _c(
                                  "v-list-item",
                                  { key: extension },
                                  [
                                    _c(
                                      "v-list-item-content",
                                      {
                                        on: {
                                          click: function ($event) {
                                            return _vm.exportData(extension)
                                          },
                                        },
                                      },
                                      [
                                        _c("a", [
                                          _vm._v(_vm._s(_vm.$t(extension))),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.isManageCompany
        ? _c("v-row", { staticClass: "mb-2" }, [_c("v-divider")], 1)
        : _vm._e(),
      !_vm.isManageCompany
        ? _c(
            "v-card",
            {
              class: _vm.$vuetify.breakpoint.smAndDown ? "mb-12" : null,
              attrs: { elevation: "0", width: "100%" },
            },
            [
              _c(
                "v-card-title",
                { staticClass: "pr-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "span",
                        {
                          staticClass: "text-subtitle-2 my-auto",
                          class: _vm.$vuetify.breakpoint.mdAndUp
                            ? "ml-4"
                            : "ml-1",
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("totalCompanies")) +
                              " : " +
                              _vm._s(_vm.allCompaniesData.length)
                          ),
                        ]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-col",
                        {
                          class: !_vm.$vuetify.breakpoint.mdAndUp
                            ? "pl-0 pr-4"
                            : "pr-6",
                          attrs: { cols: "12", lg: "4", md: "4", xl: "4" },
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "rounded-lg",
                            attrs: {
                              "prepend-inner-icon": "mdi-magnify",
                              label: _vm.$t("search"),
                              "single-line": "",
                              "hide-details": "",
                              flat: "",
                              solo: "",
                              "background-color": "grey lighten-4",
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-data-table", {
                staticClass: "elevation-0 pa-0",
                class: _vm.$vuetify.breakpoint.mdAndUp ? "ml-2" : null,
                attrs: {
                  headers: _vm.headers,
                  items: _vm.allCompaniesData,
                  "items-per-page": !_vm.$vuetify.breakpoint.mdAndUp ? -1 : 15,
                  search: _vm.search,
                  "item-key": "company_id",
                  color: "primary",
                  "checkbox-color": "primary",
                  "hide-default-footer": !_vm.$vuetify.breakpoint.mdAndUp,
                  loading: _vm.allCompaniesData.length == 0,
                  "loading-text": "Loading... Please wait",
                  "mobile-breakpoint": "950",
                  "disable-sort": "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item.name",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "text-subtitle-2 font-weight-bold ma-0",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "primary--text text-decoration-none",
                                  on: {
                                    click: function ($event) {
                                      return _vm.manageCompany(item)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.name))]
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.type",
                      fn: function (ref) {
                        var item = ref.item
                        return [_vm._v(" " + _vm._s(item.type) + " ")]
                      },
                    },
                    {
                      key: "item.state",
                      fn: function (ref) {
                        var item = ref.item
                        return [_vm._v(" " + _vm._s(item.state) + " ")]
                      },
                    },
                    {
                      key: "item.center",
                      fn: function (ref) {
                        var item = ref.item
                        return [_vm._v(" " + _vm._s(item.center) + " ")]
                      },
                    },
                    {
                      key: "item.created_at",
                      fn: function (ref) {
                        var item = ref.item
                        return [_vm._v(" " + _vm._s(item.created_at) + " ")]
                      },
                    },
                    {
                      key: "item.company_status",
                      fn: function (ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-chip",
                            {
                              staticClass: "white--text",
                              attrs: {
                                color: _vm.getColor(item.company_status),
                                small: "",
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t(item.company_status)) + " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "item.plan",
                      fn: function (ref) {
                        var item = ref.item
                        return [_vm._v(" " + _vm._s(item.plan) + " ")]
                      },
                    },
                    !_vm.$vuetify.breakpoint.mdAndUp
                      ? {
                          key: "body",
                          fn: function (ref) {
                            var items = ref.items
                            return [
                              _c(
                                "tbody",
                                _vm._l(items, function (item) {
                                  return _c(
                                    "tr",
                                    {
                                      key: item.company_id,
                                      staticClass: "pb-4",
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          attrs: {
                                            height: "200",
                                            elevation: "0",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "ml-1",
                                                  attrs: { cols: "8" },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-subtitle-2 font-weight-bold",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "primary--text text-decoration-none",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.manageCompany(
                                                                item
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(item.name)
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("v-spacer"),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pr-0",
                                                  attrs: { cols: "3" },
                                                },
                                                [
                                                  _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "white--text mr-4",
                                                      attrs: {
                                                        "x-small": "",
                                                        color: _vm.getColor(
                                                          item.company_status
                                                        ),
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              item.company_status
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "ml-1" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { cols: "4" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("type")) + ":"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pa-0 font-weight-medium",
                                                },
                                                [_vm._v(_vm._s(item.type))]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "ml-1" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { cols: "4" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("stateText")
                                                    ) + ":"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pa-0 font-weight-medium",
                                                },
                                                [_vm._v(_vm._s(item.state))]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "ml-1" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { cols: "4" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("centerText")
                                                    ) + ":"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pa-0 font-weight-medium",
                                                },
                                                [_vm._v(_vm._s(item.center))]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "ml-1" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { cols: "4" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("createdOnText")
                                                    ) + ":"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pa-0 font-weight-medium",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.created_at)
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { staticClass: "ml-1" },
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass: "pa-0",
                                                  attrs: { cols: "4" },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("plan")) + ":"
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "v-col",
                                                {
                                                  staticClass:
                                                    "pa-0 font-weight-medium",
                                                },
                                                [_vm._v(_vm._s(item.plan))]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("v-divider"),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                            ]
                          },
                        }
                      : null,
                  ],
                  null,
                  true
                ),
                model: {
                  value: _vm.selected,
                  callback: function ($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected",
                },
              }),
            ],
            1
          )
        : _vm.isManageCompany
        ? _c(
            "v-card",
            {
              attrs: {
                elevation: "0",
                width: "100%",
                "min-height": _vm.$vuetify.breakpoint.height - 160,
              },
            },
            [
              _vm.isManageCompany
                ? _c("ManageCompanyLargeScreen", {
                    attrs: {
                      "company-tab-labels": _vm.tabLabels,
                      "company-information": _vm.companyInformation,
                    },
                    on: {
                      manageCompany: _vm.manageCompany,
                      OnChangeStatus: _vm.OnChangeStatus,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("ConfirmationDialog", {
        attrs: {
          dialog: _vm.confimationDialog,
          "dialog-title":
            _vm.confimationDialogObject[_vm.companyInformation.status]
              .dialogTitle,
          "dialog-body": _vm.dialogBody,
          "dialog-button":
            _vm.confimationDialogObject[_vm.companyInformation.status]
              .dialogButton,
          "company-information": _vm.companyInformation,
        },
        on: {
          closeDialog: _vm.closeConfimationDialog,
          confirmStatus: _vm.upadateCompanyStatus,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }