<template>
  <div>
    <v-container class="pa-0">
      <v-row v-if="$vuetify.breakpoint.mdAndUp">
        <v-col
          cols="10"
          md="9"
          lg="10"
          class="text-h5 font-weight-medium"
          :class="$vuetify.breakpoint.mdAndUp ? 'pl-8' : 'pl-5'"
        >
          Disable Feature
        </v-col>
      </v-row>
      <v-row justify="start" class="py-5">
        <v-treeview
          :ref="`treeView`"
          selectable
          open-all
          dense
          :value="activeNodes"
          item-disabled="locked"
          :items="items"
          @input="updateNodes"
        >
        </v-treeview>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    companyInformation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      items: [
        { id: 1, name: "Create Tickets", value: "createTickets" }
      ],
      activeNodes: [],
      disableRolesAndPermissions: [],
    };
  },
  created() {
    this.activeNodes = [];
    let disableRolesAndPermissions =
      this.companyInformation.options.disableRolesAndPermissions || [];
    for (let i = 0; i < this.items.length; i++) {
      if (disableRolesAndPermissions.includes(this.items[i].value)) {
        this.activeNodes.push(this.items[i].id);
      }
    }
  },
  methods: {
    async updateNodes(item) {
      this.disableRolesAndPermissions = [];
      for (let i = 0; i < item.length; i++) {
        this.disableRolesAndPermissions.push(this.items[item[i] - 1].value);
      }
      let companyUpdateObject = {};
      companyUpdateObject.options = this.companyInformation.options;
      companyUpdateObject.company_id = this.companyInformation.company_id;
      companyUpdateObject.options.disableRolesAndPermissions =
        this.disableRolesAndPermissions;
      const CompanyStatus = await this.$store.dispatch(
        "updateCompany",
        companyUpdateObject,
      );
      this.companyInformation.options = companyUpdateObject.options;
      this.$emit("successAlertRoles");
    },
  },
};
</script>

<style lang="scss" scoped></style>
