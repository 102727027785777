var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        fullscreen: _vm.$vuetify.breakpoint.smAndDown,
        "max-width": "500px",
      },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("span", { staticClass: "text-h6 font-weight-medium" }, [
                _vm._v("Edit User"),
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", "aria-label": "close" },
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-container",
                { staticClass: "px-0" },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-alert",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.isUserExists,
                                  expression: "isUserExists",
                                },
                              ],
                              attrs: {
                                color: "red",
                                dismissible: "",
                                text: "",
                                icon: "mdi-alert-circle-outline",
                                dense: "",
                                type: "error",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "my-auto font-weight-regular caption",
                                },
                                [_vm._v(_vm._s(_vm.$t("userInviteError")))]
                              ),
                            ]
                          ),
                          _c(
                            "v-alert",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.emailInUse,
                                  expression: "emailInUse",
                                },
                              ],
                              attrs: {
                                color: "red",
                                dismissible: "",
                                text: "",
                                icon: "mdi-alert-circle-outline",
                                dense: "",
                                type: "error",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "my-auto font-weight-regular caption",
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("emailAllreadyRegistered"))
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "", justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.editedItem.status === "Active" ||
                          _vm.editedItem.status === "Inactive"
                            ? _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "select-view",
                                      attrs: {
                                        cols:
                                          _vm.$vuetify.breakpoint.width <= 365
                                            ? 6
                                            : 4,
                                        lg: "3",
                                        md: "3",
                                        sm: "3",
                                      },
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.statusOptions,
                                          "menu-props": {
                                            bottom: true,
                                            offsetY: true,
                                          },
                                          "small-chips": "",
                                          outlined: "",
                                          dense: "",
                                          rounded: "",
                                          required: "",
                                          label: "",
                                          "aria-label": _vm.editedItem.status,
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "selection",
                                              fn: function (ref) {
                                                var item = ref.item
                                                return [
                                                  _c(
                                                    "v-chip",
                                                    {
                                                      staticClass:
                                                        "white--text",
                                                      attrs: {
                                                        small: "",
                                                        color:
                                                          _vm.getColor(item),
                                                      },
                                                    },
                                                    [_vm._v(" " + _vm._s(item))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3204592370
                                        ),
                                        model: {
                                          value: _vm.editedItem.status,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editedItem,
                                              "status",
                                              $$v
                                            )
                                          },
                                          expression: "editedItem.status",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.nameErrors,
                              label: _vm.$t("fullName"),
                              outlined: "",
                              required: "",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.editedItem.name.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.editedItem.name.$touch()
                              },
                            },
                            model: {
                              value: _vm.editedItem.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedItem, "name", $$v)
                              },
                              expression: "editedItem.name",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.emailErrors,
                              label: _vm.$t("emailAddress"),
                              outlined: "",
                              required: "",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.editedItem.email.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.editedItem.email.$touch()
                              },
                            },
                            model: {
                              value: _vm.editedItem.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedItem, "email", $$v)
                              },
                              expression: "editedItem.email",
                            },
                          }),
                          _c("v-text-field", {
                            attrs: {
                              "error-messages": _vm.phoneErrors,
                              label: _vm.$t("phoneNumber"),
                              outlined: "",
                              required: "",
                              dense: "",
                            },
                            on: {
                              input: function ($event) {
                                return _vm.$v.editedItem.phone.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.editedItem.phone.$touch()
                              },
                            },
                            model: {
                              value: _vm.editedItem.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedItem, "phone", $$v)
                              },
                              expression: "editedItem.phone",
                            },
                          }),
                          _c("v-select", {
                            attrs: {
                              items: _vm.userRoles,
                              "item-value": "value",
                              "item-text": "text",
                              "error-messages": _vm.roleErrors,
                              "menu-props": { bottom: true, offsetY: true },
                              outlined: "",
                              required: "",
                              dense: "",
                              label: _vm.$t("role"),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$v.editedItem.role.$touch()
                              },
                              blur: function ($event) {
                                return _vm.$v.editedItem.role.$touch()
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function (ref) {
                                  var item = ref.item
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-list-item",
                                      _vm._g(
                                        _vm._b({}, "v-list-item", attrs, false),
                                        on
                                      ),
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", {
                                              staticClass: "text-left",
                                              domProps: {
                                                textContent: _vm._s(item.text),
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.editedItem.role,
                              callback: function ($$v) {
                                _vm.$set(_vm.editedItem, "role", $$v)
                              },
                              expression: "editedItem.role",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "mt-4" },
            [
              _c(
                "v-footer",
                {
                  attrs: {
                    absolute: "",
                    color: "white",
                    elevation: _vm.$vuetify.breakpoint.smAndDown ? 3 : 1,
                  },
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "px-2 mb-3",
                      class: {
                        "mr-4 d-flex justify-end":
                          _vm.$vuetify.breakpoint.mdAndUp,
                      },
                      attrs: { "no-gutters": "" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pr-3",
                          class: { "mx-2": _vm.$vuetify.breakpoint.mdAndUp },
                          attrs: { cols: "6", lg: "2", md: "2" },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none rounded-lg",
                              attrs: {
                                block: "",
                                outlined: "",
                                color: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.closeDialog()
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "pl-3",
                          class: { "mx-2": _vm.$vuetify.breakpoint.mdAndUp },
                          attrs: {
                            cols: "6",
                            lg: _vm.editedIndex !== -1 ? 2 : 3,
                            md: _vm.editedIndex !== -1 ? 2 : 3,
                          },
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none rounded-lg",
                              attrs: {
                                block: "",
                                color: "primary",
                                disabled: _vm.$v.$invalid,
                              },
                              on: { click: _vm.update },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }