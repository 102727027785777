<template>
  <v-dialog
    v-model="dialog"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6 font-weight-medium">Edit User</span>
        <v-spacer></v-spacer>
        <v-btn icon aria-label="close" @click="closeDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container class="px-0">
          <v-row no-gutters justify="center">
            <v-col cols="12">
              <v-alert
                v-show="isUserExists"
                color="red"
                dismissible
                text
                icon="mdi-alert-circle-outline"
                dense
                type="error"
              >
                <span class="my-auto font-weight-regular caption">{{
                  $t("userInviteError")
                }}</span>
              </v-alert>
              <v-alert
                v-show="emailInUse"
                color="red"
                dismissible
                text
                icon="mdi-alert-circle-outline"
                dense
                type="error"
              >
                <span class="my-auto font-weight-regular caption">{{
                  $t("emailAllreadyRegistered")
                }}</span>
              </v-alert>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center">
            <v-col cols="12">
              <v-row
                v-if="
                  editedItem.status === 'Active' ||
                  editedItem.status === 'Inactive'
                "
                no-gutters
              >
                <v-col
                  :cols="$vuetify.breakpoint.width <= 365 ? 6 : 4"
                  lg="3"
                  md="3"
                  sm="3"
                  class="select-view"
                >
                  <v-select
                    v-model="editedItem.status"
                    :items="statusOptions"
                    :menu-props="{ bottom: true, offsetY: true }"
                    small-chips
                    outlined
                    dense
                    rounded
                    required
                    label=""
                    :aria-label="editedItem.status"
                  >
                    <template #selection="{ item }">
                      <v-chip small :color="getColor(item)" class="white--text">
                        {{ item }}</v-chip
                      >
                    </template></v-select
                  >
                </v-col>
              </v-row>

              <v-text-field
                v-model="editedItem.name"
                :error-messages="nameErrors"
                :label="$t('fullName')"
                outlined
                required
                dense
                @input="$v.editedItem.name.$touch()"
                @blur="$v.editedItem.name.$touch()"
              ></v-text-field>
              <v-text-field
                v-model="editedItem.email"
                :error-messages="emailErrors"
                :label="$t('emailAddress')"
                outlined
                required
                dense
                @input="$v.editedItem.email.$touch()"
                @blur="$v.editedItem.email.$touch()"
              ></v-text-field>
              <v-text-field
                v-model="editedItem.phone"
                :error-messages="phoneErrors"
                :label="$t('phoneNumber')"
                outlined
                required
                dense
                @input="$v.editedItem.phone.$touch()"
                @blur="$v.editedItem.phone.$touch()"
              ></v-text-field>

              <v-select
                v-model="editedItem.role"
                :items="userRoles"
                item-value="value"
                item-text="text"
                :error-messages="roleErrors"
                :menu-props="{ bottom: true, offsetY: true }"
                outlined
                required
                dense
                :label="$t('role')"
                @change="$v.editedItem.role.$touch()"
                @blur="$v.editedItem.role.$touch()"
              >
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-left"
                        v-text="item.text"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="mt-4">
        <v-footer
          absolute
          color="white"
          :elevation="$vuetify.breakpoint.smAndDown ? 3 : 1"
        >
          <v-row
            no-gutters
            class="px-2 mb-3"
            :class="{
              'mr-4 d-flex justify-end': $vuetify.breakpoint.mdAndUp,
            }"
          >
            <v-col
              cols="6"
              lg="2"
              md="2"
              class="pr-3"
              :class="{ 'mx-2': $vuetify.breakpoint.mdAndUp }"
            >
              <v-btn
                block
                outlined
                class="text-none rounded-lg"
                color="primary"
                @click="closeDialog()"
              >
                {{ $t("cancel") }}
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              :lg="editedIndex !== -1 ? 2 : 3"
              :md="editedIndex !== -1 ? 2 : 3"
              class="pl-3"
              :class="{ 'mx-2': $vuetify.breakpoint.mdAndUp }"
            >
              <v-btn
                block
                class="text-none rounded-lg"
                color="primary"
                :disabled="$v.$invalid"
                @click="update"
              >
                {{ $t("update") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-footer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from "moment";
import { validationMixin } from "vuelidate";
import { helpers } from "vuelidate/lib/validators";
import ERRORS from "@/static/errors.json";
import {
  required,
  maxLength,
  minLength,
  email,
} from "vuelidate/lib/validators";
const phone = helpers.regex("serial", /^\d{10}$/);
export default {
  name: "CompanyUsersDialog",
  mixins: [validationMixin],
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    companyId: {
      type: String,
      default: "",
    },
    editItemData: {
      type: Object,
      default: () => {},
    },
  },

  validations: {
    editedItem: {
      name: {
        required,
        maxLength: maxLength(80),
        minLength: minLength(1),
      },
      phone: { required, phone },
      email: { required, email },
      role: { required },
    },
  },
  data() {
    return {
      approverRoleUsers: [],
      selected: [],
      defaultRoles: ["admin", "user"],
      editedIndex: -1,
      emailInUse: false,
      isUserExists: false,
      editedItem: {
        name: "",
        email: "",
        phone: "",
        role: [],
        status: "",
      },
      originalItem: { name: "", email: "", phone: "", role: [], status: "" },
      userRoles: [],
      statusOptions: ["Active", "Inactive"],
      usersData: [],
    };
  },
  created() {
    this.moment = moment;
    this.editItem(this.editItemData);
    if (this.companyId) {
      this.fetchCompanyData(this.companyId);
    }
  },
  methods: {
    getRoles(role) {
      switch (role) {
        case "admin":
          return this.$t("admin");
        case "user":
          return this.$t("user");
        case "approver":
          return this.$t("approver");
        default:
          return role;
      }
    },
    async fetchCompanyData(id) {
      const companyData = await this.$store.dispatch("getCompanyDataById", {
        companyId: id,
      });
      if (companyData.status !== "error") {
        const rolesAndPermissions = companyData[0].options.rolesAndPermissions;
        this.userRoles = Object.keys(rolesAndPermissions).map((role) => ({
          text: this.getRoles(role),
          value: role,
        }));
      } else {
        console.log("error");
      }
    },
    editItem(item) {
      this.isUserExists = false;
      this.emailInUse = false;
      this.editedIndex = this.usersData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.originalItem = Object.assign({}, item);
      this.editedItem.role = this.editedItem.persona;
      this.editedItem.status = this.$t(this.editedItem.status);
    },
    closeDialog() {
      this.$emit("closeCompanyUsersDialog");
      this.editedItem = Object.assign({});
    },

    async update() {
      let updateUserStatus = {};
      let userObject = {
        id: this.editedItem.user_id,
        company_id: this.companyId,
        name: this.editedItem.name,
        email: this.editedItem.email,
        phone: this.editedItem.phone,
        status: this.editedItem.status.toLowerCase(),
        role: this.defaultRoles.includes(this.editedItem.role)
          ? this.editedItem.role
          : "user",
        persona: this.editedItem.role,
        disabled_by_sysop:
          this.editedItem.status.toLowerCase() === "inactive" ? true : false,
      };
      if (this.editedItem.approver_id)
        userObject.approver_id = this.editedItem.approver_id;
      else userObject.approver_id = null;

      // Update pending user email
      if (
        this.originalItem.email !== this.editedItem.email &&
        userObject.status !== "active"
      ) {
        userObject.status = "pending";
        updateUserStatus = await this.$store.dispatch(
          "reInviteUser",
          userObject
        );
      } else {
        // Update active user
        updateUserStatus = await this.$store.dispatch("updateUser", userObject);
      }

      if (updateUserStatus.status !== "error") {
        this.closeDialog();
        this.userUpdated = true;

        if (userObject.status === "inactive") {
          this.$emit("userUpdateSuccessful", "user deactivated successfully");
        } else {
          this.$emit("userUpdateSuccessful", "user updated successfully");
        }
        this.$emit("getUsers", this.companyId);
      }
      // error when updating email to one that already exists
      else if (updateUserStatus.error === ERRORS.USER_EMAIL_ALREADY_EXIST) {
        // set the email to original email
        this.editedItem.email = this.originalItem.email;
        this.emailInUse = true;
      } else {
        this.$emit("error");
      }
    },
    getColor(status) {
      if (status.toLowerCase() === "pending") return "#C35309";
      else if (status.toLowerCase() === "inactive") return "#616161";
      else return "#1E853A";
    },
  },
  computed: {
    company_id() {
      return this.companyId;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.editedItem.name.$dirty) return errors;
      !this.$v.editedItem.name.maxLength &&
        errors.push(this.$t("nameLengthError"));
      !this.$v.editedItem.name.required &&
        errors.push(this.$t("nameRequiredError"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.editedItem.email.$dirty) return errors;
      !this.$v.editedItem.email.email &&
        errors.push(this.$t("emailValidError"));
      !this.$v.editedItem.email.required &&
        errors.push(this.$t("emailRequiredError"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.editedItem.phone.$dirty) return errors;
      !this.$v.editedItem.phone.phone &&
        errors.push(this.$t("phoneValidError"));
      !this.$v.editedItem.phone.required &&
        errors.push(this.$t("phoneRequiredError"));
      return errors;
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.editedItem.role.$dirty) return errors;
      !this.$v.editedItem.role.required &&
        errors.push(this.$t("roleRequiredError"));
      return errors;
    },
  },
};
</script>
