<template>
  <v-card
    class="roles-and-permission-large full-height"
    elevation="0"
    :min-height="$vuetify.breakpoint.height - 160"
  >
    <v-row>
      <v-btn
        icon
        aria-label="close"
        class="ml-4 pr-2 mt-4"
        @click="$emit('manageCompany')"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span class="text-h5 font-weight-medium mt-4 ml-2">{{
        companyInformation.name
      }}</span>
      <v-col
        :cols="$vuetify.breakpoint.width <= 365 ? 6 : 4"
        lg="1"
        md="2"
        sm="3"
        class="pt-4 pb-0 px-0 ml-6"
      >
        <v-select
          v-model="companyInformation.status"
          :items="statusOptions"
          :menu-props="{ bottom: true, offsetY: true }"
          small-chips
          outlined
          dense
          rounded
          required
          height="31"
          label=""
          item-value="value"
          item-text="text"
          hide-details="true"
          :aria-label="companyInformation.status"
          class="pa-0"
          @change="changeStatus"
        >
          <template #selection="{ item }">
            <v-chip small :color="getColor(item.value)" class="white--text">
              {{ item.text }}</v-chip
            >
          </template>
        </v-select>
      </v-col>

      <v-spacer></v-spacer>
      <div v-if="!onSuccess" class="my-6 mr-6">
        <v-col class="pa-0 caption date-text">
          {{ $t("createdOnText") }}:
          <span class="pa-0 font-weight-medium">
            {{ companyInformation.created_at }}
          </span>
        </v-col>
      </div>
      <v-col v-if="onSubmitFeature" class="d-flex justify-end">
        <v-alert
          dismissible
          close-icon="mdi-close"
          text
          class="mb-0"
          dense
          @input="onSuccess = false"
          color="success"
          ><v-icon color="success" class="mr-2"
            >mdi-check-circle-outline</v-icon
          >
          <span class="mb-2 mr-6 font-weight-regular text-subtitle-2">
            {{ $t("Company Information updated successfully!") }}
          </span>
        </v-alert>
      </v-col>
      <v-col v-if="onSuccess" class="d-flex justify-end">
        <v-alert
          dismissible
          close-icon="mdi-close"
          text
          class="mb-0"
          dense
          @input="onSuccess = false"
          color="success"
          ><v-icon color="success" class="mr-2"
            >mdi-check-circle-outline</v-icon
          >
          <span class="mb-2 mr-6 font-weight-regular text-subtitle-2">
            {{
              isUpdateBillingDetails
                ? $t("Billing information updated successfully!")
                : $t("Plan updated successfully!")
            }}
          </span>
        </v-alert>
      </v-col>
    </v-row>
    <v-row> <v-divider></v-divider></v-row>
    <v-row> <v-divider></v-divider></v-row>
    <v-row class="roles-and-permission-large full-height">
      <v-tabs style="border-bottom: 1px solid lightgrey">
        <v-tab
          v-for="value in companyTabLabels"
          :key="value"
          class="d-flex justify-center text-none"
          style="min-width: 20%"
          @change="changeSelectedTab(value)"
          >{{ value }}
        </v-tab>
      </v-tabs>
      <v-col class="py-0 px-0 container-border">
        <v-container fluid>
          <CompanyInformation
            v-if="selectedTab === 'Company and Billing Info'"
            :company-information="companyInformation"
          />
          <CompanyUsers
            v-if="selectedTab === 'Users'"
            :company-id="companyInformation.company_id"
          />
          <OrderSummaryCompany
            v-if="selectedTab === 'Plan Summary' && changeSubscriptionFlag"
            :company-info="companyInformation"
            @updateCompanyData="updateCompanyData"
          />
          <div v-if="showLoader" class="d-flex justify-center align-center">
            <v-progress-circular
              indeterminate
              :width="5"
              :size="80"
              color="green"
            ></v-progress-circular>
          </div>
          <RolesAndPermission
            :company-information="companyInformation"
            @successAlertRoles="successAlertRoles"
            v-if="selectedTab === 'Roles & Permissions'"
          />
        </v-container>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import CompanyUsers from "./CompanyUsers.vue";
import CompanyInformation from "../../molecules/CompanyInformation.vue";
import OrderSummaryCompany from "../../molecules/orderSummaryCompany.vue";
import RolesAndPermission from "./rolesAndPermission.vue";

export default {
  name: "ManageCompanyLargeScreen",
  components: {
    CompanyUsers,
    CompanyInformation,
    OrderSummaryCompany,
    RolesAndPermission,
  },
  props: {
    companyTabLabels: {
      type: Array,
      default: () => [],
    },
    companyInformation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedItem: null,
      changeSubscriptionFlag: false,
      onSuccess: false,
      subscriptionData: {},
      isUpdateBillingDetails: false,
      onSubmitFeature: false,
      showLoader: true,
    };
  },
  computed: {
    selectedTab() {
      return this.selectedItem || this.companyTabLabels[0];
    },
    statusOptions() {
      if (this.companyInformation.status === "pending")
        return [
          { text: "Active", value: "active" },
          { text: "Inactive", value: "inactive" },
          { text: "Pending", value: "pending" },
        ];
      else
        return [
          { text: "Active", value: "active" },
          { text: "Inactive", value: "inactive" },
        ];
    },
  },
  watch: {
    onSuccess(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.onSuccess = false;
          this.isUpdateBillingDetails = false;
        }, 8000);
      }
    },
    onSubmitFeature(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.onSubmitFeature = false;
        }, 8000);
      }
    },
  },
  async created() {
    if (
      Object.keys(this.$store.state.Subscription.subscriptionProducts)
        .length === 0
    )
      await this.fetchProductsData();
    const CompanyData = await this.$store.dispatch("getPurchaseHistoryById", {
      companyId: this.companyInformation.company_id,
    });
    CompanyData?.sort(function (a, b) {
      return new Date(b.date) - new Date(a.date);
    });
    this.subscriptionData = CompanyData[0]
      ? CompanyData[0]
      : this.companyInformation;
    this.subscriptionData = Object.assign(
      this.subscriptionData,
      this.$store.state.Subscription.subscriptionMap[
        this.subscriptionData.subscription_id
      ],
    );
    this.companyInformation.subscription = this.subscriptionData;
    this.paymentHistory = CompanyData;
    if (this.paymentHistory) {
      this.showLoader = false;
      this.changeSubscriptionFlag = true;
    }
  },
  methods: {
    async updateCompanyData(company) {
      this.companyInformation.subscription_id = company.subscription_id;
      this.companyInformation.centers = company.centers;
      this.companyInformation.storage = company.storage;
      this.companyInformation.users = company.users;
      this.companyInformation.stripe_subscription_id = company.stripe_subscription_id;
      this.companyInformation.stripe_customer_id = company.stripe_customer_id;
      this.companyInformation.payment_method = company.payment_method;
      this.companyInformation.subscription_started_at = company.subscription_started_at;
      const CompanyData = await this.$store.dispatch("getPurchaseHistoryById", {
        companyId: this.companyInformation.company_id,
      });
      CompanyData?.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      this.subscriptionData = CompanyData[0]
        ? CompanyData[0]
        : this.companyInformation;
      this.subscriptionData = Object.assign(
        this.subscriptionData,
        this.$store.state.Subscription.subscriptionMap[
          this.subscriptionData.subscription_id
        ],
      );
      this.companyInformation.subscription = this.subscriptionData;
      this.paymentHistory = CompanyData;
      if (this.paymentHistory) {
        this.showLoader = false;
        this.changeSubscriptionFlag = true;
      }
    },
    async fetchProductsData() {
      const subscriptionData = await this.$store.dispatch(
        "getSubscriptionProductsData",
      );
      if (subscriptionData.status !== "error") {
        let subscriptionObject = {};
        subscriptionData.subscriptions.forEach((element) => {
          let products = {};
          let storage = [];
          subscriptionData.products.forEach((item) => {
            let price;
            price = subscriptionData.prices.find((obj) => {
              return obj.id == item.default_price;
            });
            item.price = price;
            if (item.id === element.products.baseProduct) {
              products.baseProduct = item;
            }
            if (item.id === element.products.addOn) {
              products.addOn = item;
            }
            if (item.id === element.products.center) {
              products.center = item;
            }
            if (
              element.products.storage &&
              element.products.storage.includes(item.id)
            ) {
              storage.push(item);
            }
          });
          if (storage.length) {
            products.storage = storage;
          }
          if (Object.keys(products).length) {
            element.productsData = products;
          }
          subscriptionObject[element.subscription_id] = element;
        });
        this.$store.commit("setSubscriptionMap", subscriptionObject);
        this.$store.commit("setSubscriptionProduct", subscriptionData);
        console.log(this.$store.state.Subscription.subscriptionProducts);
      }
    },
    successAlert(isUpdateBillingDetails) {
      this.onSuccess = true;
      this.isUpdateBillingDetails = isUpdateBillingDetails;
    },
    successAlertRoles() {
      this.onSubmitFeature = true;
    },
    async fetchCompanyData() {
      const CompanyData = await this.$store.dispatch("getCompanyDataById", {
        companyId: this.companyInformation.company_id,
      });
      if (CompanyData.status !== "error") {
        this.companyInformation = CompanyData[0];
      }
    },
    getColor(status) {
      if (status && status.toLowerCase() === "pending") return "#C35309";
      else if (status && status.toLowerCase() === "inactive") return "#616161";
      else return "#1E853A";
    },
    changeSelectedTab(key) {
      this.selectedItem = key;
    },
    changeStatus() {
      this.$emit("OnChangeStatus");
    },
  },
};
</script>

<style lang="scss">
@import "../../../scss/components/roles-and-permissions.scss";
.container-border {
  border-width: thin;
  border-left: 0.5px rgba(0, 0, 0, 0.12) solid;
}
.navigation-border .v-navigation-drawer__border {
  display: none;
}
.roles-and-permission-large {
  .v-tab {
    justify-content: left;
  }
  .v-expansion-panels {
    border: 1px solid #e0e0e0;
  }
  .v-expansion-panel {
    border-bottom: 1px solid #e0e0e0;
  }
  &.full-height {
    height: calc(100% + 12px);
  }
  .v-select.v-input--dense .v-chip {
    margin: 0px;
  }
  .v-text-field--enclosed.v-input--dense:not(
      .v-text-field--solo
    ).v-text-field--outlined
    .v-input__append-inner {
    margin: 0px;
    margin-top: 4px;
  }
}
@media only screen and (max-width: 768px) {
  .v-slide-group__prev {
    display: contents !important;
  }
  .date-text {
    margin-left: 20%;
  }
}
</style>
