<template>
  <v-container fluid class="pa-2">
    <v-row v-if="$vuetify.breakpoint.mdAndUp" class="justify-space-between">
      <v-col
        cols
        class="text-h5 font-weight-medium"
        :class="$vuetify.breakpoint.mdAndUp ? 'pl-8' : 'pl-5'"
      >
        {{ $t("users") }}
      </v-col>
      <v-col cols="auto" v-if="showSuccessMsg">
        <v-alert
          type="success"
          dismissible
          max-height="250"
          dense
          text
          class="ma-0"
        >
          <span>
            {{ successMsgText }}
          </span>
        </v-alert>
      </v-col>
    </v-row>
    <v-card
      elevation="0"
      width="100%"
      :class="$vuetify.breakpoint.smAndDown ? 'ma-0 pa-0' : null"
    >
      <v-card-title class="pa-0 pb-2">
        <v-row class="ma-0 pa-0">
          <span
            class="text-subtitle-2 my-auto"
            :class="$vuetify.breakpoint.mdAndUp ? 'ml-4' : 'ml-1 mt-3 mb-3'"
            >{{ $t("totalUsers") }} : {{ usersData.length }}</span
          >
          <v-alert
            v-if="showSuccessMsg && $vuetify.breakpoint.smAndDown"
            type="success"
            dismissible
            max-height="250"
            dense
            text
            class="ma-0"
          >
            <span>
              {{ successMsgText }}
            </span>
          </v-alert>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            lg="4"
            md="4"
            xl="4"
            :class="!$vuetify.breakpoint.mdAndUp ? 'pl-0 pr-4' : 'pr-6'"
          >
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              flat
              solo
              background-color="grey lighten-4"
              class="rounded-lg"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="usersData"
        :items-per-page="!$vuetify.breakpoint.mdAndUp ? -1 : 15"
        :search="search"
        item-key="email"
        color="primary"
        class="elevation-0 pa-0"
        :class="$vuetify.breakpoint.mdAndUp ? 'ml-2' : null"
        checkbox-color="primary"
        :hide-default-footer="!$vuetify.breakpoint.mdAndUp"
        :loading="usersData.length === 0"
        loading-text="Loading... Please wait"
        mobile-breakpoint="950"
        disable-sort
      >
        <template v-slot:[`item.name`]="{ item }">
          <v-row class="text-subtitle-2 font-weight-bold ma-0">
            {{ item.name }}</v-row
          >
          <v-row class="ma-0"> {{ item.email }}</v-row>
        </template>
        <template v-slot:[`item.persona`]="{ item }">
          {{ getRoles(item.persona) }}
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at }}
        </template>
        <template v-slot:[`item.updated_at`]="{ item }">
          <v-row v-if="item.updated_at === 'Invalid date'" justify="center">
            —
          </v-row>
          <v-row v-else>
            {{ item.updated_at }}
          </v-row>
        </template>
        <template v-slot:[`item.access`]="{ item }">
          <a
            :style="{
              'pointer-events': item.status === 'inactive' ? 'none' : 'auto',
              color: item.status === 'inactive' ? 'gray !important' : 'inherit',
            }"
            class="primary--text"
            @click="impersonateUser(item.user_id)"
          >
            {{ $t("signIn") }}</a
          >
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip :color="getColor(item.status)" small class="white--text">
            {{ $t(item.status) }}
          </v-chip>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
          <v-icon
            v-if="checkPermission('editUser')"
            :disabled="companyUserId === item.user_id"
            class="mr-2"
            aria-label="Edit"
            @click="editItem(item)"
          >
            mdi-pencil-outline
          </v-icon>
        </template>
        <template v-if="!$vuetify.breakpoint.mdAndUp" v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.email" class="pb-4">
              <v-card height="200" elevation="0">
                <v-row>
                  <v-col cols="7" class="ml-1">
                    <span class="text-subtitle-2 font-weight-bold">
                      {{ item.name }}</span
                    >
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col class="pr-0">
                    <v-chip
                      x-small
                      :color="getColor(item.status)"
                      class="white--text mr-4"
                    >
                      {{ $t(item.status) }}
                    </v-chip>
                  </v-col>
                </v-row>

                <v-row class="ml-1">
                  <v-col cols="4" class="pa-0">{{ $t("role") }}:</v-col>
                  <v-col class="pa-0 font-weight-medium">
                    {{ getRoles(item.persona) }}</v-col
                  >
                </v-row>
                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("emailId") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    {{ item.email }}</v-col
                  ></v-row
                >
                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("created") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    {{ item.created_at }}</v-col
                  ></v-row
                >
                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("updated") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    <span
                      v-if="item.updated_at === 'Invalid date'"
                      justify="center"
                    >
                      —
                    </span>
                    <span v-else>
                      {{ item.updated_at }}
                    </span></v-col
                  ></v-row
                >
                <v-row class="ml-1"
                  ><v-col cols="4" class="pa-0">{{ $t("adminAccess") }}:</v-col
                  ><v-col class="pa-0 font-weight-medium">
                    <a
                      :style="{
                        'pointer-events':
                          item.status === 'inactive' ? 'none' : 'auto',
                        color: item.status === 'inactive' ? 'gray' : 'inherit',
                        'text-decoration':
                          item.status === 'inactive' ? 'none' : 'underline',
                      }"
                      class="primary--text"
                    >
                      {{ $t("signIn") }}</a
                    ></v-col
                  ></v-row
                >
                <!-- v-if="checkPermission('editUser')" -->
                <v-row class="ml-1 pt-4 mb-4">
                  <v-col cols="3" class="pa-0">
                    <v-icon
                      :disabled="companyUserId === item.user_id"
                      class="mr-3"
                      aria-label="Edit"
                      @click="editItem(item)"
                    >
                      mdi-pencil-outline
                    </v-icon>
                  </v-col>
                </v-row>
              </v-card>
              <v-divider></v-divider>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <CompanyUsersDialog
        v-if="dialog"
        :edit-item-data="editedItem"
        :dialog="dialog"
        :company-id="companyId"
        @update="update"
        @userUpdateSuccessful="userUpdateSuccessful"
        @closeCompanyUsersDialog="closeCompanyUsersDialog"
        @getUsers="getUsers"
      />
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import { validationMixin } from "vuelidate";
import { helpers } from "vuelidate/lib/validators";
import ERRORS from "@/static/errors.json";
import UserDataMixin from "@/mixins/UserData";
import CompanyUsersDialog from "./CompanyUsersDialog";
import {
  required,
  maxLength,
  minLength,
  email,
} from "vuelidate/lib/validators";
const phone = helpers.regex("serial", /^\d{10}$/);
export default {
  name: "CompanyUsers",
  mixins: [validationMixin, UserDataMixin],
  props: {
    companyId: {
      type: String,
      default: "",
    },
  },
  components: { CompanyUsersDialog },

  validations: {
    editedItem: {
      name: {
        required,
        maxLength: maxLength(80),
        minLength: minLength(1),
      },
      phone: { required, phone },
      email: { required, email },
      role: { required },
    },
  },
  data() {
    return {
      search: "",
      approverRoleUsers: [],
      dialog: false,
      selected: [],
      defaultRoles: ["admin", "user"],
      editedIndex: -1,
      emailInUse: false,
      isUserExists: false,
      isApprover: "",
      successMsgText: "",
      editedItem: {
        name: "",
        email: "",
        phone: "",
        role: [],
        status: "",
      },
      originalItem: { name: "", email: "", phone: "", role: [], status: "" },
      userRoles: [],
      statusOptions: ["Active", "Inactive"],
      headers: [
        {
          text: "User",
          value: "name",
        },
        { text: "Role", value: "role" },
        { text: "Created On", value: "created_at" },
        { text: "Updated On", value: "updated_at" },
        { text: "Status", value: "status" },
        { text: "Admin Access", value: "access" },
        { text: "Edit", value: "edit" },
      ],
      usersData: [],
      showSuccessMsg: false,
    };
  },
  created() {
    this.moment = moment;
    this.getUsers(this.companyId);
    if (this.companyId) {
      this.fetchCompanyDataById(this.companyId);
    }
  },
  watch: {
    companyId() {
      this.getUsers(this.companyId);
    },
    showSuccessMsg() {
      if (this.showSuccessMsg) {
        setTimeout(() => {
          this.showSuccessMsg = false;
        }, 5000);
      }
    },
  },
  methods: {
    getRoles(role) {
      switch (role) {
        case "admin":
          return this.$t("admin");
        case "user":
          return this.$t("user");
        case "approver":
          return this.$t("approver");
        default:
          return role;
      }
    },
    async fetchCompanyDataById(id) {
      const companyData = await this.$store.dispatch("getCompanyDataById", {
        companyId: id,
      });
      if (companyData.status !== "error") {
        const rolesAndPermissions = companyData[0].options.rolesAndPermissions;
        this.userRoles = Object.keys(rolesAndPermissions);
      } else {
        console.log("error");
      }
    },
    userUpdateSuccessful(message) {
      this.successMsgText = message;
      this.showSuccessMsg = true;
    },
    editItem(item) {
      this.isUserExists = false;
      this.emailInUse = false;
      this.isApprover = false;
      this.editedIndex = this.usersData.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.originalItem = Object.assign({}, item);
      this.editedItem.role = this.editedItem.persona;
      this.editedItem.status = this.$t(this.editedItem.status);
      this.isApprover = this.editedItem.approver_id ? true : false;
      this.dialog = true;
    },

    update() {
      this.getUsers(this.companyId);
      this.dialog = false;
      this.showSuccessMsg = true;
    },
    close() {
      this.dialog = false;
    },
    async getUsers(company_id) {
      const userData = await this.$store.dispatch("getCompanyUsers", {
        company_id: company_id,
      });
      if (userData.status !== "error") {
        userData.sort(function (a, b) {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        userData.forEach((item) => {
          item.created_at = moment(item.created_at).format("MM/DD/YYYY");
          item.updated_at = moment(item.updated_at).format("MM/DD/YYYY");
          item.role[0] = this.$t(item.persona);
        });
        this.usersData = userData;
      }
    },
    getColor(status) {
      if (status.toLowerCase() === "pending") return "#C35309";
      else if (status.toLowerCase() === "inactive") return "#616161";
      else return "#1E853A";
    },
    closeCompanyUsersDialog() {
      this.dialog = false;
    },
  },
  computed: {
    company_id() {
      return this.companyId;
    },
    approversList() {
      return this.approverRoleUsers.filter((element) => {
        return element.user_id !== this.editedItem.user_id;
      });
    },
    approverRoles() {
      const rolesAndPermissions =
        this.$store.state.Company.company.options.rolesAndPermissions;
      return Object.keys(rolesAndPermissions).filter((key) => {
        return rolesAndPermissions[key].includes("approveTickets");
      });
    },
    companyUserId() {
      return this.$store.state.User.user.user_id;
    },
    companyUserRole() {
      return this.$store.state.User.user.role[0];
    },
    isApproverCheck() {
      if (this.isApprover)
        if (this.editedItem.approver_id) return false;
        else return true;
      else return false;
    },
    approverCheck() {
      const plan =
        this.$store.state.Subscription.subscriptionMap[
          this.$store.state.Company.company.subscription_id
        ]?.name;
      return (
        !(
          this.editedItem.role === "admin" ||
          this.editedItem.role === "approver" ||
          !this.editedItem.role
        ) &&
        plan !== "Free" &&
        this.approverRoleUsers.length !== 0
      );
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.editedItem.name.$dirty) return errors;
      !this.$v.editedItem.name.maxLength &&
        errors.push(this.$t("nameLengthError"));
      !this.$v.editedItem.name.required &&
        errors.push(this.$t("nameRequiredError"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.editedItem.email.$dirty) return errors;
      !this.$v.editedItem.email.email &&
        errors.push(this.$t("emailValidError"));
      !this.$v.editedItem.email.required &&
        errors.push(this.$t("emailRequiredError"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.editedItem.phone.$dirty) return errors;
      !this.$v.editedItem.phone.phone &&
        errors.push(this.$t("phoneValidError"));
      !this.$v.editedItem.phone.required &&
        errors.push(this.$t("phoneRequiredError"));
      return errors;
    },
    roleErrors() {
      const errors = [];
      if (!this.$v.editedItem.role.$dirty) return errors;
      !this.$v.editedItem.role.required &&
        errors.push(this.$t("roleRequiredError"));
      return errors;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
