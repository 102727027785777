var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "roles-and-permission-large full-height",
      attrs: {
        elevation: "0",
        "min-height": _vm.$vuetify.breakpoint.height - 160,
      },
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-btn",
            {
              staticClass: "ml-4 pr-2 mt-4",
              attrs: { icon: "", "aria-label": "close" },
              on: {
                click: function ($event) {
                  return _vm.$emit("manageCompany")
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
            1
          ),
          _c("span", { staticClass: "text-h5 font-weight-medium mt-4 ml-2" }, [
            _vm._v(_vm._s(_vm.companyInformation.name)),
          ]),
          _c(
            "v-col",
            {
              staticClass: "pt-4 pb-0 px-0 ml-6",
              attrs: {
                cols: _vm.$vuetify.breakpoint.width <= 365 ? 6 : 4,
                lg: "1",
                md: "2",
                sm: "3",
              },
            },
            [
              _c("v-select", {
                staticClass: "pa-0",
                attrs: {
                  items: _vm.statusOptions,
                  "menu-props": { bottom: true, offsetY: true },
                  "small-chips": "",
                  outlined: "",
                  dense: "",
                  rounded: "",
                  required: "",
                  height: "31",
                  label: "",
                  "item-value": "value",
                  "item-text": "text",
                  "hide-details": "true",
                  "aria-label": _vm.companyInformation.status,
                },
                on: { change: _vm.changeStatus },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-chip",
                          {
                            staticClass: "white--text",
                            attrs: {
                              small: "",
                              color: _vm.getColor(item.value),
                            },
                          },
                          [_vm._v(" " + _vm._s(item.text))]
                        ),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.companyInformation.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.companyInformation, "status", $$v)
                  },
                  expression: "companyInformation.status",
                },
              }),
            ],
            1
          ),
          _c("v-spacer"),
          !_vm.onSuccess
            ? _c(
                "div",
                { staticClass: "my-6 mr-6" },
                [
                  _c("v-col", { staticClass: "pa-0 caption date-text" }, [
                    _vm._v(" " + _vm._s(_vm.$t("createdOnText")) + ": "),
                    _c("span", { staticClass: "pa-0 font-weight-medium" }, [
                      _vm._v(
                        " " + _vm._s(_vm.companyInformation.created_at) + " "
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.onSubmitFeature
            ? _c(
                "v-col",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "mb-0",
                      attrs: {
                        dismissible: "",
                        "close-icon": "mdi-close",
                        text: "",
                        dense: "",
                        color: "success",
                      },
                      on: {
                        input: function ($event) {
                          _vm.onSuccess = false
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { color: "success" } },
                        [_vm._v("mdi-check-circle-outline")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "mb-2 mr-6 font-weight-regular text-subtitle-2",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "Company Information updated successfully!"
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.onSuccess
            ? _c(
                "v-col",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "mb-0",
                      attrs: {
                        dismissible: "",
                        "close-icon": "mdi-close",
                        text: "",
                        dense: "",
                        color: "success",
                      },
                      on: {
                        input: function ($event) {
                          _vm.onSuccess = false
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mr-2", attrs: { color: "success" } },
                        [_vm._v("mdi-check-circle-outline")]
                      ),
                      _c(
                        "span",
                        {
                          staticClass:
                            "mb-2 mr-6 font-weight-regular text-subtitle-2",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.isUpdateBillingDetails
                                  ? _vm.$t(
                                      "Billing information updated successfully!"
                                    )
                                  : _vm.$t("Plan updated successfully!")
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("v-row", [_c("v-divider")], 1),
      _c("v-row", [_c("v-divider")], 1),
      _c(
        "v-row",
        { staticClass: "roles-and-permission-large full-height" },
        [
          _c(
            "v-tabs",
            { staticStyle: { "border-bottom": "1px solid lightgrey" } },
            _vm._l(_vm.companyTabLabels, function (value) {
              return _c(
                "v-tab",
                {
                  key: value,
                  staticClass: "d-flex justify-center text-none",
                  staticStyle: { "min-width": "20%" },
                  on: {
                    change: function ($event) {
                      return _vm.changeSelectedTab(value)
                    },
                  },
                },
                [_vm._v(_vm._s(value) + " ")]
              )
            }),
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0 px-0 container-border" },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _vm.selectedTab === "Company and Billing Info"
                    ? _c("CompanyInformation", {
                        attrs: {
                          "company-information": _vm.companyInformation,
                        },
                      })
                    : _vm._e(),
                  _vm.selectedTab === "Users"
                    ? _c("CompanyUsers", {
                        attrs: {
                          "company-id": _vm.companyInformation.company_id,
                        },
                      })
                    : _vm._e(),
                  _vm.selectedTab === "Plan Summary" &&
                  _vm.changeSubscriptionFlag
                    ? _c("OrderSummaryCompany", {
                        attrs: { "company-info": _vm.companyInformation },
                        on: { updateCompanyData: _vm.updateCompanyData },
                      })
                    : _vm._e(),
                  _vm.showLoader
                    ? _c(
                        "div",
                        { staticClass: "d-flex justify-center align-center" },
                        [
                          _c("v-progress-circular", {
                            attrs: {
                              indeterminate: "",
                              width: 5,
                              size: 80,
                              color: "green",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedTab === "Roles & Permissions"
                    ? _c("RolesAndPermission", {
                        attrs: {
                          "company-information": _vm.companyInformation,
                        },
                        on: { successAlertRoles: _vm.successAlertRoles },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }